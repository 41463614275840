import { deployment, ENVIRONMENT_CODE } from 'shared/services/config';
import { checkForNewVersion } from 'versionCheck';

// If it's a production build, check for new version and possibly do a hard reload
if (process.env.NODE_ENV === 'production') {
  checkForNewVersion();
}

function importIndex() {
  switch (deployment.countryCode) {
    case ENVIRONMENT_CODE.LT:
      /* eslint-disable */
      import('./lt/index');
      break;
    case ENVIRONMENT_CODE.EE:
      /* eslint-disable */
      import('./ee/index');
      break;
    case ENVIRONMENT_CODE.LV:
      /* eslint-disable */
      import('./lv/index');
      break;
    default:
      console.error(
        'No or Unsupported ENV: ',
        deployment.config.environment.name,
        deployment.config.environment.code
      );
  }
}

deployment.setConfig().then(() => {
  importIndex();
});
