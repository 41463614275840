import base64ToBlob from 'shared/util/base64ToBlob';

const downloadBase64 = ({ base64, name }) => {
  const fileURL = URL.createObjectURL(base64ToBlob(base64));
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = fileURL;
  a.style = 'display: none';
  a.download = name;
  a.click();
};

export default downloadBase64;
