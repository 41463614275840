export const ATTACHMENT_STATUS = {
  SIGNED: 'SIGNED',
  PREPARED: 'PREPARED',
  WAITING_FOR_SIGNATURE: 'WAITING_FOR_SIGNATURE',
};

export const UPLOAD_STATUS = {
  IN_UPLOAD_QUEUE: 'IN_UPLOAD_QUEUE',
  UPLOADING: 'UPLOADING',
  SUCCESS: 'SUCCESS',
};
