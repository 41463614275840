import history from 'shared/services/history';
import axios from 'axios';
import { get } from 'lodash';
import qs from 'qs';
import { normalizeById, normalizeWithPaging } from 'shared/services/normalize';
import { normalize, schema } from 'normalizr';
import poll from 'shared/util/poll';
import { AuthStatus } from 'shared/model';
import { saveAs } from 'file-saver';
import base64ToBlob from 'shared/util/base64ToBlob';
import { constructFileName } from 'shared/util/attachmentUtils';
import downloadBase64 from 'shared/util/downloadBase64';
import { APPLICATION_SOURCES, ASSIGN_TYPE, DEALER_CONTRACT_STATE, VEHICLE_TYPE, } from 'shared/components/application/applicationShape';
import log from 'shared/util/log';
import { setSseApplicationFilter } from 'shared/sagas/sseApplicationFilter';
import { currentVersion } from 'versionCheck';
import { deployment } from 'shared/services/config';
import queryClient from 'shared/services/useQuery/queryClient';

const LOCAL_STORAGE_LOCALE_KEY = 'locale';
const LOCAL_STORAGE_JWT_TOKEN_KEY = 'lp.BackOffice.Token';
const LOCAL_STORAGE_JWT_REFRESH_TOKEN_KEY = 'lp.BackOffice.RefreshToken';
const REFRESH_PATH = '/v1/bo/auth/refresh';

const SIGNING_TYPE = {
  SMART_ID: 'smart-id',
  MOBILE_ID: 'mobile-id',
};

export const Locale = {
  get: () => localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY),
  set: (locale) => localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, locale),
};

export const JWT = {
  token: {
    get: () => sessionStorage.getItem(LOCAL_STORAGE_JWT_TOKEN_KEY),
    set: (token) => {
      sessionStorage.setItem(LOCAL_STORAGE_JWT_TOKEN_KEY, token);
    },
  },
  refreshToken: {
    get: () => sessionStorage.getItem(LOCAL_STORAGE_JWT_REFRESH_TOKEN_KEY),
    set: (refreshToken) => {
      sessionStorage.setItem(LOCAL_STORAGE_JWT_REFRESH_TOKEN_KEY, refreshToken);
    },
  },
  clear: () => {
    sessionStorage.removeItem(LOCAL_STORAGE_JWT_TOKEN_KEY);
    sessionStorage.removeItem(LOCAL_STORAGE_JWT_REFRESH_TOKEN_KEY);
  },
};

export const sseAuthHeaders = () => {
  const token = JWT.token.get();
  const tokenRefresh = JWT.refreshToken.get();
  if (token !== null) {
    return { headers: { Authorization: token, Authorization2: tokenRefresh } };
  }
  return { headers: {} };
};

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(token) {
  subscribers = subscribers.filter((callback) => callback(token));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

const ignoreErrorFieldList = ['headers', 'stack'];

const replacerIgnoredField = (key, value) => {
  if (ignoreErrorFieldList.indexOf(key) > -1) {
    return undefined;
  }
  return value;
};

const refreshTokens = async () => {
  if (isAlreadyFetchingAccessToken) {
    return;
  }
  isAlreadyFetchingAccessToken = true;
  await axios
    .post(REFRESH_PATH, { refreshToken: JWT.refreshToken.get() })
    .then(({ data: { token, refreshToken } }) => {
      JWT.token.set(token);
      JWT.refreshToken.set(refreshToken);
      onAccessTokenFetched(token);
    })
    .catch((error) => {
      subscribers = [];
      JWT.clear();
      queryClient.clear();
      if (get(error, 'response.status') === 401) {
        if (history.location.pathname !== '/login') {
          history.push(`/login?redirect=${history.location.pathname}`);
        }
      }
    })
    .finally(() => {
      isAlreadyFetchingAccessToken = false;
    });
};

export const createClient = () => {
  const instance = axios.create({
    validateStatus: (status) => status >= 200 && status < 300,
    paramsSerializer: {
      encode: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    },
  });

  instance.interceptors.request.use(
    (config) => {
      const token = JWT.token.get();
      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      }
      // eslint-disable-next-line no-param-reassign
      config.headers['X-UI-Build-Version'] = currentVersion;
      return config;
    },
    (error) => Promise.reject(error),
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status !== 401) {
        if (error.response.status === 400 || error.response.status === 404) {
          log.warn(JSON.stringify(error, replacerIgnoredField));
        } else {
          log.error(JSON.stringify(error, replacerIgnoredField));
        }
        return Promise.reject(error);
      }

      if (error.config.url === REFRESH_PATH) {
        subscribers = [];
        JWT.clear();
        return Promise.reject(error);
      }

      const { config } = error;

      refreshTokens();

      return new Promise((resolve) => {
        addSubscriber((token) => {
          config.headers.Authorization = `Bearer ${token}`;
          resolve(instance.request(config));
        });
      });
    },
  );

  return instance;
};

export const requests = createClient();

export const normalizeDealers = (payload) => {
  const dealerSchema = new schema.Entity('dealers', { idAttribute: 'id' });
  return normalize(payload.data.dealers, [dealerSchema]);
};

export const normalizeDealerships = (payload) => {
  const dealershipSchema = new schema.Entity('dealerships', { idAttribute: 'id' });
  return normalize(payload.data.dealerships, [dealershipSchema]);
};

export const normalizeUsers = (payload) => {
  const userSchema = new schema.Entity('users', { idAttribute: 'id' });
  return normalize(payload.data.users, [userSchema]);
};

export const normalizeStatuses = (payload) => {
  const statusSchema = new schema.Entity('statuses', { idAttribute: 'id' });
  return normalize(payload.data.statuses, [statusSchema]);
};

export const normalizeCustomers = normalizeWithPaging('customers');

export const normalizeNewCars = normalizeWithPaging('cars');

export const normalizeContractsWithContracts = normalizeWithPaging('contracts');

export const Auth = {
  me: () => requests.get('/v1/bo/auth/me'),
  MobileId: {
    login: ({ phone, personCode }) => requests.post('/v1/bo/auth/mobile-id/login', {
      phone, personCode, isKlixApp: deployment.isKlix
    }),
    status: ({ token, pollInterval = 2000, maxRetries = 60 }) => poll(
      () => requests.get(`/v1/bo/auth/mobile-id/login/${token}?isKlixApp=${deployment.isKlix}`),
      (response) => get(response, 'data.status') === AuthStatus.WAITING,
      maxRetries,
      pollInterval,
    ),
  },
  SmartId: {
    login: ({ personCode, countryCode }) => requests.post('/v1/bo/auth/smart-id/login', {
      personCode, countryCode, isKlixApp: deployment.isKlix
    }),
    status: ({ token, pollInterval = 2000, maxRetries = 60 }) => poll(
      () => requests.get(`/v1/bo/auth/smart-id/login/${token}?isKlixApp=${deployment.isKlix}`),
      (response) => get(response, 'data.status') === AuthStatus.WAITING,
      maxRetries,
      pollInterval,
    ),
  },
  IdCard: {
    verifyLogin: (token) => requests.get(`/v1/bo/auth/id-card/login/${token}:verify?isKlixApp=${deployment.isKlix}`),
  },
  EmailPassword: {
    login: (payload) => requests.post('/v1/bo/auth/password/login', { ...payload }).then(({ data }) => data),
    resetPassword: (payload) => requests.post('/v1/login/password:reset', { ...payload }).then(({ data }) => data),
    changePassword: (payload) => requests.post('/v1/login/password:change', { ...payload }).then(({ data }) => data),
    verifyToken: (payload) => requests.post('/v1/login/password:verify-token', { ...payload }).then(({ data }) => data)
  },
  selectAccount: ({ sessionId, selectedDealershipId }) => requests.post('/v1/bo/auth/select-account', { sessionId, selectedDealershipId }),
};

export const Dealer = {
  get: (dealerId) => requests.get(`/v1/bo/dealers/${dealerId}`),
  getForEdit: (dealerId) => requests.get(`/v1/bo/dealers/edit/${dealerId}`),
  all: () => requests.get('/v1/bo/dealers').then(normalizeDealers),
  add: (payload) => requests.post('/v1/bo/dealers', { ...payload }),
  edit: (payload) => requests.patch('/v1/bo/dealers/edit', { ...payload }),
  remove: ({ dealerId, reassignToDealerId }) => {
    const searchParams = new URLSearchParams([['reassignToDealerId', reassignToDealerId]]);
    return requests.delete(`/v1/bo/dealers/${dealerId}?${searchParams.toString()}`);
  },
  getBrands: () => requests.get('/v1/brands'),
};

export const Dealership = {
  get: (dealershipId) => requests.get(`/v1/bo/dealerships/${dealershipId}`),
  all: () => requests.get('/v1/bo/dealerships').then(normalizeDealerships),
  add: (payload) => requests.post('/v1/bo/dealerships', { ...payload }),
  getForEdit: (dealershipId) => requests.get(`/v1/bo/dealerships/edit/${dealershipId}`),
  edit: (payload) => requests.patch('/v1/bo/dealerships/edit', { ...payload }),
  remove: (id) => requests.delete(`/v1/bo/dealerships/${id}`),
  getCampaignInformation: (dealershipCode) => requests.get(`/v1/bo/dealerships/${dealershipCode}/campaigns`),
};

export const BackOfficeUser = {
  all: () => requests.get('/v1/users').then(normalizeUsers),
  allByRoles: (params) => requests.get('/v1/users', { params }),
  create: ({
    name,
    surname,
    phone,
    email,
    personCode,
    countryCode,
    role,
    companyCode,
    notificationReceivers,
    assistantId,
  }) => requests.post('/v1/users', {
    name,
    surname,
    phone,
    email,
    personCode,
    countryCode,
    role,
    companyCode,
    notificationReceivers,
    assistantId,
  }),
  edit: ({
    id,
    name,
    surname,
    phone,
    email,
    personCode,
    countryCode,
    role,
    companyCode,
    notificationReceivers,
    assistantId,
  }) => requests.patch(`/v1/users/${id}`, {
    name,
    surname,
    phone,
    email,
    personCode,
    countryCode,
    role,
    companyCode,
    notificationReceivers,
    assistantId
  }),
  remove: ({ userId, reassignToUser }) => {
    const queryParams = reassignToUser ? `?reassignToUser=${reassignToUser}` : '';
    return requests.delete(`/v1/users/${userId}${queryParams}`);
  },
  getRoles: ({ userId = null }) => requests.get('/v1/roles', { params: { userId } }),
  getAssignment: ({ assignedUserId }) => requests.get(`/v1/users/${assignedUserId}`),
  get: (userId) => requests.get(`/v1/users/${userId}`),
};

export const Statuses = {
  all: () => requests.get('/v2/bo/customers/information-statuses').then(normalizeStatuses),
  create: (name) => requests.post('/v2/bo/customers/information-statuses', { name }),
  edit: ({ id, name }) => requests.patch(`/v2/bo/customers/information-statuses/${id}`, { name }),
  remove: (id) => requests.delete(`/v2/bo/customers/information-statuses/${id}`),
};

export const NewCars = {
  query: ({ limit, offset, term = null }) => requests
    .get('/v1/bo/cars', {
      params: {
        limit,
        offset,
        term,
      },
    })
    .then(normalizeNewCars),
  add: ({ vehicleType, make, model, isHighRisk = false }) => requests.post('/v1/bo/cars', {
    vehicleType,
    make,
    model,
    isHighRisk,
  }),
  remove: (id) => requests.delete(`/v1/bo/cars/${id}`),
};

export const Vehicles = {
  vehicles: () => requests.get('/v1/bo/vehicles'),
  types: () => requests.get('/v1/bo/vehicles/types'),
};

export const Consent = {
  getAllByPersonCode: (personCode) => requests.get(`/v1/bo/customers/consents?personCode=${personCode}`),
  getByCustomerIdAndSpousePersonCode: ({ customerId, personCode }) => requests
    .get(`/v1/bo/customers/${customerId}/spouses/consent?personCode=${personCode}`)
    .then((response) => {
      if (response.consents) {
        return Promise.reject();
      }
      return response;
    }),
  download: ({ consentId, intl }) => requests.get(`/v1/bo/consents/${consentId}/document`).then((response) => {
    saveAs(base64ToBlob(response.data.content), constructFileName(intl, response.data));
  }),
  downloadAdditionalDocuments: (documentId) => requests
    .get(`/v1/documents/${documentId}/file`, { responseType: 'arraybuffer' })
    .then((response) => {
      const fileName = response.headers['x-file-name'];
      saveAs(new Blob([response.data]), fileName);
    }),
  downloadAttachment: ({ consentId, documentId, type }) => requests
    .get(`/v1/bo/customers/consent/${consentId}/attachments`, {
      responseType: 'arraybuffer',
      params: { documentId, type },
    })
    .then((response) => {
      const fileName = response.headers['x-file-name'];
      saveAs(new Blob([response.data]), fileName);
    }),
};

export const requestFormData = (data) => {
  const formData = new FormData();
  formData.set('request', new Blob([JSON.stringify(data)], { type: 'application/json' }));
  return formData;
};

export const Customer = {
  getSharedCustomer: (id) => requests.get(`/v2/bo/customers/${id}`),
  queryCustomers: (params = {}) => requests.get('/v2/bo/customers', { params }).then(normalizeCustomers),
  getComments: ({ id }) => requests.get(`/v1/bo/customers/${id}/comments`),
  getDetails: ({ id }) => requests.get(`/v1/bo/customers/${id}`),
  getContractCustomer: ({ id }) => requests.get(`/v1/bo/customers/${id}:contractInfo`),
  addComment: ({ id, data, commentFiles }) => {
    const formData = requestFormData(data);

    commentFiles.forEach((file) => {
      formData.append('commentFiles', file);
    });

    return requests.post(`/v1/bo/customers/${id}/comment`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  getContracts: ({ customerId }) => requests.get('/v1/bo/contracts', { params: { customerId } }),
  updateProfile: ({ id, email, phone, pep }) => requests.patch(`/v2/bo/customers/${id}`, {
    email,
    phone,
    politicallyExposedPerson: pep,
  }),
  synchronize: ({ id }) => requests.post(`/v1/bo/customers/${id}:synchronize`),
  pollCreditLimitStatus: ({ id, pollInterval = 2000, maxRetries = 90 }) => poll(
    () => requests.get(`/v2/bo/customers/${id}:creditLimitStatus`),
    (response) => response.data.coreResponded === false,
    maxRetries,
    pollInterval,
  ),
  delete: ({ id, userId }) => requests.post('/v1/bo/customers/delete', { customerId: id, userId }),
  createCustomer: async (
    data,
    financeDocuments,
    otherDocuments,
    consent,
    idDocuments,
    spouseConsent,
    spouseIdDocuments,
    spouseFinanceDocuments,
  ) => {
    const formData = requestFormData(data);

    financeDocuments.forEach((file) => {
      formData.append('financeFiles', file);
    });

    otherDocuments.forEach((file) => {
      formData.append('otherFiles', file);
    });

    formData.append('consent', consent[0]);

    idDocuments.forEach((document) => {
      formData.append('idDocuments', document);
    });

    spouseFinanceDocuments.forEach((file) => {
      formData.append('spouseFinanceFiles', file);
    });

    formData.append('spouseConsent', spouseConsent[0]);

    spouseIdDocuments.forEach((document) => {
      formData.append('spouseIdDocuments', document);
    });

    await refreshTokens();

    return requests.post('/v2/bo/customers', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  createSpouse: (data, spouseIdDocuments, spouseConsent, spouseFinanceDocuments) => {
    const formData = requestFormData(data);

    spouseFinanceDocuments.forEach((file) => {
      formData.append('spouseFinanceFiles', file);
    });

    formData.append('spouseConsent', spouseConsent[0]);

    spouseIdDocuments.forEach((document) => {
      formData.append('spouseIdDocuments', document);
    });

    return requests.post('/v2/bo/customers/spouse', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  downloadCustomerCommentFile: (fileId, commentId, customerId) => requests
    .get(`/v1/bo/customers/${customerId}/comments/${commentId}/files/${fileId}`)
    .then((response) => {
      saveAs(base64ToBlob(response.data.content), response.data.fileName);
    }),
  saveCustomerInformationStatus: (id, informationStatusId) => requests.patch(`/v2/bo/customers/${id}/information-status`, { informationStatusId }),
  assign: (id) => requests.post(`/v2/bo/customers/${id}:assign`),
  unassign: (id) => requests.post(`/v2/bo/customers/${id}:unassign`),
  reassign: (id) => requests.post(`/v2/bo/customers/${id}:reassign`),
};

export const Config = {
  getConfig: () => requests.get(`/v1/config?cacheControl=${new Date().getTime()}`),
};

export const Users = {
  registerUser: ({ contractId, email }) => requests.post('/v2/bo/customers:registerViaEmail', { contractId, email }),
};

export const BackOffice = {
  // READ MORE about `\uFEFF' at the content start:
  // https://stackoverflow.com/questions/19492846/javascript-to-csv-export-encoding-issue
  exportCustomersCsv: (params = {}) => requests
    .get('/v2/customers:export', { headers: { Accept: 'text/csv' }, params })
    .then((response) => {
      saveAs(
        new Blob([`\uFEFF${response.data}`], { type: 'text/csv;charset=UTF-8' }),
        `Customers_${new Date().toISOString()}.csv`,
      );
    }),
  // READ MORE about `\uFEFF' at the content start:
  // https://stackoverflow.com/questions/19492846/javascript-to-csv-export-encoding-issue
  exportApplicationsCSV: ({
    term,
    representativeIds,
    dealershipIds,
    createdFrom,
    createdTo,
    types,
    states,
    sortBy,
    order,
    showAssigned,
    sources,
    carTypes,
    assignTypes,
    showOverdueFirst,
    privateDealer,
  }) => {
    requests
      .post('/v1/contracts:export', {
        sortBy,
        order,
        showAssigned,
        createdFrom,
        createdTo,
        types,
        sources: [].concat(...sources.map((key) => APPLICATION_SOURCES[key])),
        assignTypes: [].concat(...assignTypes.map((key) => ASSIGN_TYPE[key])),
        carTypes: [].concat(...carTypes.map((key) => VEHICLE_TYPE[key])),
        states: transformApplicationStates(states),
        representativeIds,
        dealershipIds,
        privateDealer,
        term,
        showOverdueFirst,
      }, { headers: { Accept: 'text/csv' } })
      .then((response) => {
        saveAs(
          new Blob([`\uFEFF${response.data}`], { type: 'text/csv;charset=UTF-8' }),
          `Contracts_${new Date().toISOString()}.csv`,
        );
      });
  },
  getCurrentEuriborRates: () => requests.get('/v1/bo/euribor'),
};

export const Document = {
  downloadTemplate: (vehicleMake, type, dealershipId) => {
    let request = `/v1/contracts/template?type=${type}`;
    if (dealershipId) {
      request = request.concat(`&dealershipId=${dealershipId}`);
    }
    if (vehicleMake) {
      request = request.concat(`&make=${vehicleMake}`);
    }
    return requests.get(request).then((response) => {
      downloadBase64({
        base64: response.data.document,
        name: response.data.originalFilename,
      });
    });
  },
  downloadFilledConsent: (consentData) => {
    const request = '/v2/bo/customers/consent:generateConsentWithoutSaving';
    return requests.post(request, consentData).then((response) => {
      downloadBase64({
        base64: response.data.content,
        name: response.data.fileName,
      });
    });
  },
  download: (id) => requests.get(`/v1/documents/${id}/file`, { responseType: 'arraybuffer' }).then((response) => {
    const fileName = response.headers['x-file-name'];
    saveAs(
      new Blob([response.data]),
      fileName && fileName.includes('.') ? fileName : `${fileName}.pdf`,
    );
  }),
};

export const Applications = {
  queryUnfinished: ({
    limit,
    page,
    term,
    representativeIds,
    dealershipIds,
    createdFrom,
    createdTo,
    types,
    states,
    sortBy,
    order,
    showAssigned,
    sources,
    carTypes,
    assignTypes,
    showOverdueFirst,
    privateDealer,
  }) => {
    const apiStates = transformApplicationStates(states);

    setSseApplicationFilter({
      term,
      representativeIds,
      dealershipIds,
      createdFrom,
      createdTo,
      types,
      apiStates,
      sources,
      carTypes,
      assignTypes,
      showAssigned,
      privateDealer,
    });

    return requests
      .post('/v1/contracts', {
        limit,
        page,
        term,
        representativeIds,
        dealershipIds,
        createdFrom,
        createdTo,
        types,
        states: apiStates,
        sortBy,
        order,
        showAssigned,
        sources: [].concat(...sources.map((key) => APPLICATION_SOURCES[key])),
        carTypes: [].concat(...carTypes.map((key) => VEHICLE_TYPE[key])),
        assignTypes: [].concat(...assignTypes.map((key) => ASSIGN_TYPE[key])),
        privateDealer,
        showOverdueFirst,
      })
      .then(normalizeContractsWithContracts);
  },
  getBrandingInfo: ({ id }) => requests.get(`/v1/bo/contracts/${id}/branding-info`),
  getWithActions: ({ id }) => requests.get(`/v1/bo/contracts/${id}/with-actions`),
  pollPrivateCustomer: ({ id, pollInterval = 1000, maxRetries = 20 }) => poll(
    () => requests.get(`/v1/bo/contracts/${id}/private-customer`),
    (response) => response.data?.customerId == null,
    maxRetries,
    pollInterval,
  ),
  pollCustomerRegistrationEmail: ({ id, pollInterval = 1000, maxRetries = 20 }) => poll(
    () => requests.get(`/v1/bo/contracts/${id}/private-customer`),
    (response) => response.data === null || response.data.customerRegistrationEmail === null,
    maxRetries,
    pollInterval,
  ),
  assign: (id) => requests.post(`/v1/bo/contracts/${id}:assign`),
  unassign: (id) => requests.post(`/v1/bo/contracts/${id}:unassign`),
  reassign: (id) => requests.post(`/v1/bo/contracts/${id}:reassign`),
  getCommentsPage: ({ contractId, page = 0, limit = 5 }) => {
    const requestParams = {
      params: {
        page,
        limit,
      },
    };
    return requests.get(`/v1/contracts/${contractId}/comments/page`, requestParams);
  },
  addComment: ({ contractId, data, commentFiles }) => {
    const formData = requestFormData(data);

    for (let i = 0; i < commentFiles.length; i += 1) {
      formData.append('commentFiles', commentFiles[i]);
    }

    return requests.post(`/v1/contracts/${contractId}/comment`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  uploadTemporaryFile: (file) => {
    const formData = new FormData();
    formData.append('temporaryFile', file);

    return requests.post('/v1/files/temporary', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  deleteTemporaryFile: (fileId) => requests.delete(`/v1/files/temporary/${fileId}`),
  saveApplication: (data) => requests.post('/v1/bo/contracts', data),
  approveApplication: ({ id, approvalComment }) => requests.post(`/v1/bo/contracts/${id}:approve`, { approvalComment }),
  validateApplicationById: (id) => requests.post(`/v1/bo/contracts/${id}:validate-for-approval`),
  validateApplication: (data) => requests.post('/v1/bo/contracts:validate-for-approval', data),
  updateApproveApplication: (id, data) => requests.post(`/v1/bo/contracts/${id}:updateAndApprove`, data),
  updateApplication: (id, data) => requests.patch(`/v1/bo/contracts/${id}`, data),
  approveCalculatorApplication: (data) => requests.post('/v1/bo/contracts:createAndApprove', data),
  requestPayment: (id) => requests.post(`/v1/bo/contracts/${id}:requestPayment`),
  cancel: ({ contractId, payload }) => requests.post(`/v1/contracts/${contractId}:cancel`, payload),
  submitVin: ({ contractId, vinNumber }) => requests.post(`/v1/bo/contracts/${contractId}:prepareRemainingDocuments`, { vinNumber }),
  representatives: () => requests.get('/v1/bo/representatives').then(normalizeById('representatives')),
  getColleagueEmailSuggestions: ({ term }) => requests.get('/v1/users:findColleagues', { params: { term } }),
  getUserSuggestions: ({ term, signingParty }) => requests.get('/v1/user-suggestions', { params: { term, signingParty } })
    .then(({ data }) => data),
  getDealerEmailSuggestions: ({ term, dealershipId, companyCode }) => requests.get(
    '/v1/users/dealers',
    { params: { term, dealershipId, companyCode } }
  ),
  insuranceCompanies: () => requests.get('/v1/bo/insurance-companies'),
  states: () => requests.get('/v1/bo/contracts/states'),
  downloadContractCommentFile: (fileId, commentId, contractId) => requests
    .get(`/v1/bo/contracts/${contractId}/comments/${commentId}/files/${fileId}`)
    .then((response) => {
      saveAs(base64ToBlob(response.data.content), response.data.fileName);
    }),
  getVehicleTypes: (dealershipId) => {
    const searchParams = new URLSearchParams();
    dealershipId && searchParams.set('dealershipId', dealershipId);
    const request = `/v1/bo/calculator/vehicle-types?${searchParams.toString()}`;
    return requests.get(request);
  },
  copyApplication: (contractId) => requests.post(`/v1/bo/contracts/${contractId}:copy`),
  prepareAgreement: ({ id, settlement, deliveryDate, vin }) => requests.post(`/v1/bo/contracts/${id}:prepare`, {
    settlement,
    deliveryDate,
    vin,
  }).then(({ data }) => data),
  coBorrowerSignRequest: ({ contractId, email }) => requests.post(`/v1/bo/customers/co-borrower/email?contractId=${contractId}`, { email }),
};

export const Attachments = {
  signWithMobileId: (attachmentId, phone) => Attachments.sign(attachmentId, SIGNING_TYPE.MOBILE_ID, phone),
  signWithSmartId: (attachmentId) => Attachments.sign(attachmentId, SIGNING_TYPE.SMART_ID),
  sign: (attachmentId, signingType, phone) => (
    requests.post(`/v2/bo/contracts/attachments/${attachmentId}:sign?type=${signingType}`, { phone })
  ),
  getSigningMobileIdStatus: (attachmentId, token) => Attachments.getSigningStatus(attachmentId, token, SIGNING_TYPE.MOBILE_ID),
  getSigningSmartIdStatus: (attachmentId, token) => Attachments.getSigningStatus(attachmentId, token, SIGNING_TYPE.SMART_ID),
  getSigningStatus: (attachmentId, token, type) => (
    requests.get(`/v2/bo/contracts/attachments/${attachmentId}/signing-status/${token}?type=${type}`)
  ),
  getDocumentInfo: (attachmentId) => requests.get(`/v2/bo/contracts/attachments/${attachmentId}`),
  download: (attachmentId, intl) => (
    Attachments.getContent(attachmentId).then((data) => (saveAs(base64ToBlob(data.content), constructFileName(intl, data))))
  ),
  add: ({ applicationId, type, temporaryFilesId }) => (
    requests.post(`/v2/bo/contracts/${applicationId}/attachments`, { type, temporaryFilesId }).then(({ data }) => data)
  ),
  uploadSignableAttachment: ({ applicationId, type, temporaryFilesId, signingParties }) => (
    requests.post(`/v2/bo/contracts/${applicationId}/attachments:upload-signable`, { type, temporaryFilesId, signingParties })
      .then(({ data }) => data)
  ),
  delete: ({ applicationId, attachmentId }) => requests.delete(`/v2/bo/contracts/${applicationId}/attachments/${attachmentId}`),
  getContent: (attachmentId) => requests.get(`/v1/bo/attachments/${attachmentId}`).then(({ data }) => data),
  requestUserToSign: (attachmentId, signerType, userId, email) => requests.post(
    `/v2/bo/contracts/attachments/${attachmentId}:sendSignRequest`,
    { signerType, userId, email }
  ),
  requestExternalUserToSign: (attachmentId, email) => requests.post(
    `/v2/bo/contracts/attachments/${attachmentId}:send-external-sign-request`,
    { email }
  ),
  getContractAttachments: ({ id }) => requests.get(`/v2/bo/contracts/${id}/attachments`).then(({ data }) => data),
  getAttachmentList: (limit, page, searchTerm, dateFrom, dateTo, attachmentTypes, signingStatuses, contractTypes) => {
    const payload = {
      limit,
      page,
      searchTerm,
      createdFrom: dateFrom,
      createdTo: dateTo,
      attachmentTypes,
      signingStatuses,
      contractTypes,
    };
    return requests.post('/v2/bo/attachments', payload).then(({ data }) => data || []);
  },
};

export const Calculator = {
  calculate: (requestBody) => requests.post('/v1/bo/calculator:calculate', requestBody),
};

export const EmailOffer = {
  saveAndSend: ({ data, commercialFiles, otherFiles }) => {
    const formData = requestFormData(data);

    commercialFiles.forEach((file) => {
      formData.append('commercialFiles', file);
    });

    otherFiles.forEach((file) => {
      formData.append('otherFiles', file);
    });

    return requests.post('/v1/bo/contracts/emailOffer:send', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
};

export const copyApplication = async (contractId) => {
  const response = await Applications.copyApplication(contractId);
  return response.data.contractId;
};

export const Companies = {
  get: (params = {}) => requests.get('/v1/bo/companies', { params }),
};

export const Address = {
  get: (params = {}) => requests.get('/v1/bo/addresses', { params }),
};

function transformApplicationStates(states) {
  const apiStates = [...states];
  if (apiStates.includes(DEALER_CONTRACT_STATE.SUBMITTED)) {
    apiStates.splice(apiStates.indexOf(DEALER_CONTRACT_STATE.SUBMITTED), 1);
    apiStates.push(DEALER_CONTRACT_STATE.SUBMITTED_FOR_AUTOMATIC_REVIEW);
    apiStates.push(DEALER_CONTRACT_STATE.SUBMITTED_FOR_MANUAL_REVIEW);
  }
  if (apiStates.includes(DEALER_CONTRACT_STATE.CONTRACT_PREPARING)) {
    apiStates.push(DEALER_CONTRACT_STATE.CONTRACT_PREPARING_MANUALLY);
  }
  return apiStates;
}

// Klix services
export const PayLaterApplication = {
  add: (payload) => requests.post('/v1/pay-later-applications', { ...payload }).then(({ data }) => data),
  get: (params) => requests.get('/v1/pay-later-applications', { params }),
  sync: (id) => requests.post(`/v1/pay-later-applications/${id}:sync`),
  toggleProductTransferredToBuyer: (id) => requests.post(`/v1/pay-later-applications/${id}:toggle-product-transferred-to-buyer`),
  getStores: () => requests.get('/v1/pay-later-applications/stores').then(({ data }) => data),
  getById: (id) => requests.get(`/v1/pay-later-applications/${id}`).then(({ data }) => data),
  exportApplicationsCsv: (params) => requests
    .get('/v1/pay-later-applications/csv', { headers: { Accept: 'text/csv' }, params })
    .then((response) => {
      saveAs(
        new Blob([`\uFEFF${response.data}`], { type: 'text/csv;charset=UTF-8' }),
        `Applications_${new Date().toISOString()}.csv`,
      );
    }),
  saveComments: ({ id, comments }) => requests.patch(`/v1/pay-later-applications/${id}`, { comments }),
};

export const Merchant = {
  getFinancingProducts: (params) => requests.get('/v1/merchants/financing-products', { params }).then(({ data }) => data),
  getStores: () => requests.get('/v1/merchants/employees/stores').then(({ data }) => data),
  add: (payload) => requests.post('/v1/merchants', { ...payload }).then(({ data }) => data),
  edit: ({ payload, merchantId }) => requests.put(`/v1/merchants/${merchantId}`, { ...payload }),
  getList: () => requests.get('/v1/merchants').then(({ data }) => data),
  get: (id) => requests.get(`/v1/merchants/${id}`).then(({ data }) => data),
  markRemoved: (id) => requests.patch(`/v1/merchants/${id}`),
};

export const Employee = {
  get: ({ merchantId, employeeId }) => requests.get(`/v1/merchants/${merchantId}/employees/${employeeId}`).then(({ data }) => data),
  add: ({ payload, merchantId }) => requests.post(`/v1/merchants/${merchantId}/employees`, { ...payload }).then(({ data }) => data),
  edit: ({ payload, merchantId, employeeId, }) => requests.put(`/v1/merchants/${merchantId}/employees/${employeeId}`, { ...payload }),
  markRemoved: ({ merchantId, employeeId, reassignToEmployeeId }) => (
    requests.patch(`/v1/merchants/${merchantId}/employees/${employeeId}`, { reassignToEmployeeId })
  ),
};
// -----------
