import { deployment } from 'shared/services/config';
import { UPLOAD_STATUS } from 'shared/components/document/DocumentConstants';
import { AttachmentType } from 'shared/model';

const BDOC_FILE = 'bdoc';
export const PDF_FILE = 'pdf';

export const constructFileName = (intl, { attachmentType, filename, extension, originalFilename }) => {
  if (deployment.isEE && extension === BDOC_FILE) {
    return filename;
  }
  if (deployment.isLV && originalFilename) {
    return originalFilename && originalFilename.replace(/ /g, '_');
  }
  if ([AttachmentType.INSURANCE, AttachmentType.INVOICE, AttachmentType.OTHER].includes(attachmentType)) {
    return originalFilename || filename;
  }

  const attachmentTypeName = intl.formatMessage({
    id: `applicationPage.attachment.fileType.${attachmentType.toLowerCase()}`,
  });
  return `${attachmentTypeName}.${extension}`;
};

export const kbToMb = (kb) => kb / (10 ** 6);

export const getTotalFileSizeInMb = (files = []) => {
  if (!files) {
    return 0;
  }
  return kbToMb(files.flatMap((flatFiles) => flatFiles.map((file) => file.size)).reduce((a, b) => a + b, 0));
};

export const validateFileExtension = (fileName, allowedFileExtensions = []) => {
  const fileExtensionPattern = /\.[0-9a-z]+$/i;
  const extension = fileName.match(fileExtensionPattern);
  if (extension && extension.length > 0) {
    return allowedFileExtensions && allowedFileExtensions.length > 0
      ? allowedFileExtensions.includes(extension[0].toLowerCase())
      : deployment.config.allowedFiles.includes(extension[0].toLowerCase());
  }
  return false;
};

export const isDuplicatedFile = (files, newFile) => files.some((oldFile) => (
  newFile.name === oldFile.name &&
  newFile.size === oldFile.size &&
  newFile.lastModified === oldFile.lastModified &&
  newFile.type === oldFile.type
));

export const getUniqueFileId = (file) => `${file.name}_${file.size}_${file.lastModified}_${file.type}`;

export const uploadFinished = (document) => !document.find((file) => file.status !== UPLOAD_STATUS.SUCCESS);
