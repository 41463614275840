import { QueryClient } from '@tanstack/react-query';
import axios from 'axios';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        // Current version of axios has a bug, where it throws if null is passed
        if (failureCount > 1 || !axios.isAxiosError(error || undefined)) {
          return false;
        }
        const { response } = error;
        return !!response && response.status === 404;
      },
    },
  },
});

export default queryClient;
