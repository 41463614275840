import log from 'loglevel';
import remote from 'loglevel-plugin-remote';
import { currentVersion } from 'versionCheck';
import { SOURCE } from 'shared/constants/source';

let userId = null;

export const setUserId = (id) => {
  userId = id;
};

const customJSON = (logMessage) => ({
  buildVersion: currentVersion,
  message: logMessage.message,
  userId,
  source: SOURCE.BO,
  level: logMessage.level.label,
  stacktrace: logMessage.stacktrace,
});

log.enableAll();

const apiPath = '/v1/logging/log';
remote.apply(log, {
  format: customJSON,
  url: apiPath,
  stacktrace: {
    depth: 10,
    excess: 3,
  },
});

export default log;
