import { Config } from 'shared/services/api';
import { APPLICATION_TYPE } from 'shared/components/application/applicationShape';

// eslint-disable-next-line
window._____APP_CONFIG_____ = { ...process.env };

export const ENVIRONMENT_CODE = {
  LT: 'LT',
  LV: 'LV',
  EE: 'EE',
  KLIX_LT: 'KLIX-LT',
  KLIX_LV: 'KLIX-LV',
  KLIX_EE: 'KLIX-EE',
};

export const ENVIRONMENT_NAME = {
  LOCAL: 'LOCAL',
  DEV: 'DEV',
  TEST: 'TEST',
  PROD: 'PROD',
};

const buildAllowedFilesList = () => {
  const joinedList = process.env.REACT_APP_ALLOWED_FILES;
  if (joinedList) {
    return joinedList.split(';');
  }
  return [];
};

export const resolvePath = (path) => `${process.env.PUBLIC_URL}/${path}`;

const configFromReactEnv = {
  environment: {
    code: process.env.REACT_APP_ENV_CODE,
    name: process.env.REACT_APP_ENV_NAME,
  },
  showFeature: {
    signableAttachmentsListCompany: process.env.REACT_APP_ATTACHMENT_LIST_COMPANY_SHOW === 'true',
    signableAttachmentListFarmer: process.env.REACT_APP_ATTACHMENT_LIST_FARMER_SHOW === 'true',
    insuranceOffer: process.env.REACT_APP_INSURANCE_OFFER_SHOW === 'true',
    customerManagerName: process.env.REACT_APP_CUSTOMER_MANAGER_NAME_SHOW === 'true',
    spouseRegistration: process.env.REACT_APP_SPOUSE_REGISTRATION_SHOW === 'true',
    showFarmer: process.env.REACT_APP_SHOW_FARMER === 'true',
    contractRequestedStatus: process.env.REACT_APP_REQUESTED_STATUS === 'true',
    privateDealer: process.env.REACT_APP_PRIVATE_DEALER === 'true',
    documentSigning: process.env.REACT_APP_DOCUMENT_SIGNING === 'true',
    creditServiceType: process.env.REACT_APP_CREDIT_SERVICE_TYPE === 'true',
    showDifferentSeller: process.env.REACT_APP_SHOW_DIFFERENT_SELLER === 'true',
    showAttachmentListPage: process.env.REACT_APP_SHOW_ATTACHMENT_LIST_PAGE === 'true',
    showCustomerExportButton: process.env.REACT_APP_SHOW_CUSTOMER_EXPORT_BUTTON === 'true',
    showApplicationExportButton: process.env.REACT_APP_SHOW_APPLICATION_EXPORT_BUTTON === 'true',
    showCampaignSelection: process.env.REACT_APP_SHOW_CAMPAIGN_SELECTION === 'true',
    letItSnow: process.env.REACT_APP_LET_IT_SNOW === 'true',
    coBorrowerEnabled: process.env.REACT_APP_CO_BORROWER_ENABLED === 'true',
    externalSigning: process.env.REACT_APP_EXTERNAL_SIGNING === 'true'
  },
  hotjar: {
    enabled: process.env.REACT_APP_HOTJAR_ENABLED === 'true',
  },
  allowedFiles: buildAllowedFilesList(),
  paymentSchedule: {
    link: {
      en: process.env.REACT_APP_PAYMENT_SCHEDULE_LINK_EN,
      local: process.env.REACT_APP_PAYMENT_SCHEDULE_LINK_LOCAL,
    },
    company: {
      en: process.env.REACT_APP_PAYMENT_SCHEDULE_COMPANY_EN,
      local: process.env.REACT_APP_PAYMENT_SCHEDULE_COMPANY_LOCAL,
    },
    private: {
      en: process.env.REACT_APP_PAYMENT_SCHEDULE_PRIVATE_EN,
      local: process.env.REACT_APP_PAYMENT_SCHEDULE_PRIVATE_LOCAL,
    },
  },
  defaultCalculatorView: {
    client: process.env.REACT_APP_DEFAULT_CALCULATOR_VIEW_CLIENT,
    service: process.env.REACT_APP_DEFAULT_CALCULATOR_VIEW_SERVICE,
  },
  deferredVatPercent: process.env.REACT_APP_DEFERRED_VAT_PERCENT,
};

export const deployment = {
  config: {
    environment: {
      code: undefined,
      name: undefined,
    },
    showFeature: undefined,
    hotjar: undefined,
  },
  async setConfig() {
    if (process.env.REACT_APP_ENV_CODE) {
      this.config = configFromReactEnv;
      return;
    }
    const response = await Config.getConfig();
    this.config = response.data;
  },
  get isLT() {
    return this.config.environment.code === ENVIRONMENT_CODE.LT ||
      this.config.environment.code === ENVIRONMENT_CODE.KLIX_LT;
  },
  get isLV() {
    return this.config.environment.code === ENVIRONMENT_CODE.LV ||
      this.config.environment.code === ENVIRONMENT_CODE.KLIX_LV;
  },
  get isEE() {
    return this.config.environment.code === ENVIRONMENT_CODE.EE ||
      this.config.environment.code === ENVIRONMENT_CODE.KLIX_EE;
  },
  get isKlix() {
    return this.config.environment.code === ENVIRONMENT_CODE.KLIX_LT ||
      this.config.environment.code === ENVIRONMENT_CODE.KLIX_LV ||
      this.config.environment.code === ENVIRONMENT_CODE.KLIX_EE;
  },
  get isKlixEE() {
    return this.config.environment.code === ENVIRONMENT_CODE.KLIX_EE;
  },
  get isProductionEnv() {
    return this.config.environment.name === ENVIRONMENT_NAME.PROD;
  },
  get isDevelopmentEnv() {
    return this.config.environment.name === ENVIRONMENT_NAME.LOCAL ||
      this.config.environment.name === ENVIRONMENT_NAME.DEV;
  },
  getEnabledApplicationTypes() {
    return this.config.showFeature.showFarmer
      ? APPLICATION_TYPE.all()
      : APPLICATION_TYPE.withoutFarmer();
  },
  get countryCode() {
    if (this.isLT) {
      return ENVIRONMENT_CODE.LT;
    }
    if (this.isLV) {
      return ENVIRONMENT_CODE.LV;
    }
    if (this.isEE) {
      return ENVIRONMENT_CODE.EE;
    }
    throw new Error(`Unknown countryCode for build ${this.config.environment.code}`);
  }

};
