import PropTypes from 'prop-types';
import { values } from 'lodash';

export const STATE = [
  'CONTRACT_PREPARING',
  'CONTRACT_PREPARING_MANUALLY',
  'CONTRACT_REQUESTED',
  'CONTRACT_PREPARED',
  'OFFER',
  'SUBMITTED',
  'SUBMITTED_FOR_MANUAL_REVIEW',
  'SUBMITTED_FOR_AUTOMATIC_REVIEW',
  'ACCEPTED_AUTOMATICALLY',
  'REJECTED_AUTOMATICALLY',
  'VALUATION',
  'ACCEPTED',
  'ACCEPTED_WITH_CHANGES',
  'REJECTED',
  'MISSING_INFORMATION',
  'EXPIRED',
  'CANCELLED',
  'CONTRACT_SIGNED',
  'CONTRACT_SIGNED_BY_CUSTOMER',
  'PAYMENT_REQUESTED',
  'DISBURSED'
];

export const DEALER_CONTRACT_STATE = {
  OFFER: 'OFFER',
  SUBMITTED: 'SUBMITTED',
  SUBMITTED_FOR_AUTOMATIC_REVIEW: 'SUBMITTED_FOR_AUTOMATIC_REVIEW',
  SUBMITTED_FOR_MANUAL_REVIEW: 'SUBMITTED_FOR_MANUAL_REVIEW',
  ACCEPTED_AUTOMATICALLY: 'ACCEPTED_AUTOMATICALLY',
  REJECTED_AUTOMATICALLY: 'REJECTED_AUTOMATICALLY',
  VALUATION: 'VALUATION',
  ACCEPTED: 'ACCEPTED',
  ACCEPTED_WITH_CHANGES: 'ACCEPTED_WITH_CHANGES',
  REJECTED: 'REJECTED',
  MISSING_INFORMATION: 'MISSING_INFORMATION',
  EXPIRED: 'EXPIRED',
  CANCELLED: 'CANCELLED',
  CONTRACT_PREPARING: 'CONTRACT_PREPARING',
  CONTRACT_PREPARING_MANUALLY: 'CONTRACT_PREPARING_MANUALLY',
  CONTRACT_PREPARED: 'CONTRACT_PREPARED',
  CONTRACT_REQUESTED: 'CONTRACT_REQUESTED',
  PAYMENT_REQUESTED: 'PAYMENT_REQUESTED',
  DISBURSED: 'DISBURSED',
};

export const APPLICATION_TYPE = {
  PRIVATE: 'PRIVATE',
  COMPANY: 'COMPANY',
  FARMER: 'FARMER',
  all: () => [APPLICATION_TYPE.PRIVATE, APPLICATION_TYPE.COMPANY, APPLICATION_TYPE.FARMER],
  withoutFarmer: () => [APPLICATION_TYPE.PRIVATE, APPLICATION_TYPE.COMPANY],
};

export const APPLICATION_SOURCES = {
  DEALER: ['DEALER'],
  WEB: ['PRIVATE_CUSTOMER', 'BUSINESS_OFFER', 'BUSINESS_CUSTOMER'],
  CL: ['CL'],
  AGENT: ['AGENT'],
  BANK: ['BANK'],
  PARTNER: ['PARTNER'],
};

export const VEHICLE_TYPE = {
  CAR: 'CAR',
  AGRICULTURE: 'AGRICULTURE',
  TRUCK: 'TRUCK',
  MOTORCYCLE: 'MOTORCYCLE',
  LIGHT_COMMERCIAL_VEHICLE: 'LIGHT_COMMERCIAL_VEHICLE',
  COMBINE: 'COMBINE',
  CONSTRUCTION_EQUIPMENT: 'CONSTRUCTION_EQUIPMENT',
  EXCAVATOR: 'EXCAVATOR',
  AGRICULTURE_ATTACHMENT: 'AGRICULTURE_ATTACHMENT',
  LIFTING_EQUIPMENT: 'LIFTING_EQUIPMENT',
  LOADER: 'LOADER',
  INDUSTRIAL_EQUIPMENT: 'INDUSTRIAL_EQUIPMENT',
  OTHER_FIXED_ASSET: 'OTHER_FIXED_ASSET',
  FORESTRY: 'FORESTRY',
  SPECIAL_AGRICULTURE_EQUIPMENT: 'SPECIAL_AGRICULTURE_EQUIPMENT',
  TRAILER: 'TRAILER'
};

export const ASSIGN_TYPE = {
  ASSIGNED: 'ASSIGNED',
  UNASSIGNED: 'UNASSIGNED',
};

export default PropTypes.shape({
  id: PropTypes.string,
  lastChanged: PropTypes.string,
  offerName: PropTypes.string,
  state: PropTypes.oneOf(values(STATE)),
  client: PropTypes.string,
  contacts: PropTypes.string,
  object: PropTypes.string,
  offerNumber: PropTypes.string,
  dealerRepresentative: PropTypes.string,
  type: PropTypes.oneOf(values(APPLICATION_TYPE)),
  customerId: PropTypes.string,
  contractCreatorType: PropTypes.oneOf([].concat(...Object.values(APPLICATION_SOURCES))),
  creatorContacts: PropTypes.string,
  commercialOfferAttachmentId: PropTypes.string,
  paymentScheduleAttachmentId: PropTypes.string,
  privateCustomer: PropTypes.shape({
    customerId: PropTypes.string,
    personCode: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    customerRegistrationEmail: PropTypes.string,
    spouseConsentEmail: PropTypes.string,
    coBorrowerEmail: PropTypes.string,
    consentId: PropTypes.string,
  }),
  coBorrower: PropTypes.shape({
    personCode: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    registrationEmail: PropTypes.string,
    consentId: PropTypes.string,
  }),
});

export function showApplicationStatus(state) {
  switch (state) {
    case DEALER_CONTRACT_STATE.CONTRACT_PREPARING_MANUALLY:
      return DEALER_CONTRACT_STATE.CONTRACT_PREPARING;
    case DEALER_CONTRACT_STATE.SUBMITTED_FOR_AUTOMATIC_REVIEW:
      return DEALER_CONTRACT_STATE.SUBMITTED;
    case DEALER_CONTRACT_STATE.SUBMITTED_FOR_MANUAL_REVIEW:
      return DEALER_CONTRACT_STATE.SUBMITTED;
    default:
      return state;
  }
}
