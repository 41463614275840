export const UserRole = {
  ADMIN: 'ROLE_ADMIN',
  SALES: 'ROLE_SALES',
  CALL_CENTER: 'ROLE_CALL_CENTER',
  AGENT: 'ROLE_AGENT',
  AGENT_ADMIN: 'ROLE_AGENT_ADMIN',
  BANK: 'ROLE_BANK',
  DEALER_ADMIN: 'ROLE_DEALER_ADMIN',
  DEALER_REPRESENTATIVE: 'ROLE_DEALER_REPRESENTATIVE',
  // Klix related roles below
  MERCHANT_ADMIN: 'ROLE_MERCHANT_ADMIN',
  MERCHANT_EMPLOYEE: 'ROLE_MERCHANT_EMPLOYEE',
  MERCHANT_EMPLOYEE_LIMITED: 'ROLE_MERCHANT_EMPLOYEE_LIMITED',
  KLIX_ADMIN: 'ROLE_KLIX_ADMIN',
  // ------------------------
  allDealer: () => [UserRole.DEALER_ADMIN, UserRole.DEALER_REPRESENTATIVE],
  allAuthorizedBackOffice: () => [UserRole.ADMIN, UserRole.SALES, UserRole.CALL_CENTER],
  allAgent: () => [UserRole.AGENT, UserRole.AGENT_ADMIN],
  allBackOffice: () => [
    UserRole.ADMIN,
    UserRole.SALES,
    UserRole.CALL_CENTER,
    UserRole.AGENT,
    UserRole.AGENT_ADMIN,
    UserRole.BANK,
  ],
  // Klix related role lists below
  allMerchant: () => [
    UserRole.MERCHANT_ADMIN,
    UserRole.MERCHANT_EMPLOYEE,
    UserRole.MERCHANT_EMPLOYEE_LIMITED
  ],
  allKlixExceptLimited: () => [
    UserRole.MERCHANT_ADMIN,
    UserRole.MERCHANT_EMPLOYEE,
    UserRole.KLIX_ADMIN,
  ],
  allExceptMerchant: () => [
    UserRole.ADMIN,
    UserRole.SALES,
    UserRole.CALL_CENTER,
    UserRole.DEALER_ADMIN,
    UserRole.DEALER_REPRESENTATIVE,
    UserRole.AGENT,
    UserRole.AGENT_ADMIN,
    UserRole.BANK
  ],
  merchantManagement: () => [UserRole.KLIX_ADMIN, UserRole.MERCHANT_ADMIN],
  // ------------------------
  isDealer: (userRoles) => userRoles.some((role) => UserRole.allDealer().includes(role)),
  isAgent: (userRoles) => userRoles.some((role) => UserRole.allAgent().includes(role)),
  isBank: (userRoles) => userRoles.some((role) => UserRole.BANK === role),
  canViewAssignedUser: (userRoles) => UserRole.isAgent(userRoles) || UserRole.isDealer(userRoles) || UserRole.isBank(userRoles),
  canReasignUserOnDelete: (userRole) => ![UserRole.AGENT, UserRole.AGENT_ADMIN, UserRole.BANK].includes(userRole),
  // Klix related predicates below
  isMerchant: (userRoles) => userRoles.some((role) => UserRole.allMerchant().includes(role)),
  isMerchantAdmin: (userRoles) => userRoles.some((role) => role === UserRole.MERCHANT_ADMIN),
  isKlixAdmin: (userRoles) => userRoles.some((role) => role === UserRole.KLIX_ADMIN),
  isKlixUser: (userRoles) => UserRole.isMerchant(userRoles) || UserRole.isKlixAdmin(userRoles),
  canReasignMerchantEmployeeOnDelete: (userRole) => UserRole.KLIX_ADMIN === userRole,
  // ------------------------
};
