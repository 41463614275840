import { schema, normalize } from 'normalizr';
import { pick } from 'lodash';

export const normalizeById = (entity) => (payload) => {
  const templateSchema = new schema.Entity(entity, { idAttribute: 'id' });
  return normalize(payload.data[entity], [templateSchema]);
};

export const normalizeWithPaging = (entity) => (payload) => {
  const { offset, page, limit, total } = pick(payload.data, 'offset', 'page', 'limit', 'total');
  const normalized = normalizeById(entity)(payload);
  return { ...normalized, paging: { offset, page, limit, total } };
};
