let sseApplicationFilter = {
  term: '',
  representativeIds: [],
  dealershipIds: [],
  createdFrom: null,
  createdTo: null,
  types: [],
  states: [],
  sources: [],
  carTypes: [],
  assignTypes: [],
  showAssigned: false,
};

export const setSseApplicationFilter = (filters) => {
  sseApplicationFilter = {
    term: (filters.term || '').trim(),
    representativeIds: filters.representativeIds || [],
    dealershipIds: filters.dealershipIds || [],
    createdFrom: filters.createdFrom,
    createdTo: filters.createdTo,
    types: filters.types || [],
    states: filters.states || [],
    sources: filters.sources || [],
    carTypes: filters.carTypes || [],
    assignTypes: filters.assignTypes || [],
    showAssigned: filters.showAssigned,
  };
};

const matchesTerm = (term, value) => (value || '').includes(term);

export const applicationSatisfiesFilters = (application, currentUserId) => {
  const {
    term,
    representativeIds,
    dealershipIds,
    createdFrom,
    createdTo,
    types,
    states,
    sources,
    carTypes,
    assignTypes,
    showAssigned,
  } = sseApplicationFilter;

  if (term.length > 3) {
    const matcher = (value) => matchesTerm(term, value);
    const anyTermMatches = matcher(application.offerName) ||
      matcher(application.agreementNumber) ||
      matcher(application.car) ||
      matcher(application.clientName) ||
      matcher(application.clientCode) ||
      matcher(application.clientEmail) ||
      matcher(application.clientPhone);
    if (!anyTermMatches) {
      return false;
    }
  }

  if (representativeIds.length > 0 && !representativeIds.includes(application.dealerId)) {
    return false;
  }

  if (dealershipIds.length > 0 && !dealershipIds.includes(application.dealershipId)) {
    return false;
  }

  const created = new Date(application.created);
  if (createdFrom && created < createdFrom) {
    return false;
  }
  if (createdTo && created > createdTo) {
    return false;
  }

  if (types.length > 0 && !types.includes(application.type)) {
    return false;
  }

  if (states.length > 0 && !states.includes(application.state)) {
    return false;
  }

  if (sources.length > 0 && !sources.includes(application.contractCreatorType)) {
    return false;
  }

  if (carTypes.length > 0 && !carTypes.includes(application.carType)) {
    return false;
  }

  const isAssigned = !!application.assignedUserId;
  const showAssignedToAll = assignTypes.includes('ASSIGNED');
  const showUnassigned = assignTypes.includes('UNASSIGNED');

  if (assignTypes.length > 0 && !((isAssigned && showAssignedToAll) || (!isAssigned && showUnassigned))) {
    return false;
  }

  return !showAssigned || application.assignedUserId === currentUserId;
};
