/**
 * source: https://gist.github.com/briancavalier/842626#gistcomment-1288165
 * Retry function or promise without loosing promise chain
 * @param {function} promiseFn - function that returns a promise
 * @param {function} shouldPollFn - function that returns a boolean
 * @param {number}   retries - number of retries
 * @param {number}   interval - retry interval
 */
export default function poll(promiseFn, shouldPollFn, retries = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    promiseFn()
      .then((response) => {
        if (retries === 1) {
          reject({ response });
          return;
        }

        if (!shouldPollFn(response)) {
          resolve(response);
          return;
        }

        setTimeout(() => {
          poll(promiseFn, shouldPollFn, retries - 1, interval).then(resolve, reject);
        }, interval);
      })
      .catch(reject);
  });
}
